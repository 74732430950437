export { default as routesConfig } from './routes'

import environmentConfig from './environmentConfig'
import { storageKey } from './storageKey'

const isDevEnv: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const envConfig = isDevEnv ? environmentConfig.development : environmentConfig.production
const imgConfig = 'https://gateway.dev.meu-solutions.com/vis-staging'
interface ApiConfig {
  [key: string]: string
}
interface Config {
  isDevEnv: boolean
  storageKey: {
    auth: string
    orderList: string
  }
  apiConfig: ApiConfig
  imgConfig: string
}

const Config: Config = {
  isDevEnv,
  storageKey,
  apiConfig: {
    ...envConfig
  },
  imgConfig
}

export default Config
