import axiosClient from './axiosClient'

import { payloadPostUser, payloadPutUser } from './types/user'

const userApi = {
  getAll: () => {

    const url = '/identityusers/getall'
    return axiosClient.get(url)
  },
  postUser: (data: payloadPostUser) => {
    const url = '/identityusers/register'
    return axiosClient.post(url, data)
  },
  updateUser: (data: { id: string; payload: payloadPutUser }) => {
    const url = `/identityusers/updateuser?id=${data.id}`
    return axiosClient.put(url, data.payload)
  },
  lockUser: (id: string) => {
    const url = `/identityusers/lock?Id=${id}`
    return axiosClient.put(url)
  },
  unlockUser: (id: string) => {
    const url = `/identityusers/unlock?Id=${id}`
    return axiosClient.put(url)
  },
  resetPassword: (id: string) => {
    const url = `/identityusers/reset-password/${id}`
    return axiosClient.post(url)
  },
  deleteUser: (id: string) => {
    const url = `/identityusers/deleteuser?UserId=${id}`
    return axiosClient.delete(url)
  }
}

export default userApi
