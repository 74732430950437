import axiosClient from './axiosClient'

const notificationApi = {
  getNotificationByUserId: () => {
    const url = `/notification/getnotificationbyuserid`
    return axiosClient.get(url)
  },
  readNotification: (params: any) => {
    const url = `/notification/readanotification?NotiId=${params}`
    return axiosClient.put(url)
  },
  deleteNotification: () => {
    const url = `/notification/delteallnotification`
    return axiosClient.delete(url)
  },
}

export default notificationApi
