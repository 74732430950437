import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import notificationApi from '~/api/notificationApi';
import serviceRequest from '~/app/serviceRequest'

export const getAllNotificationByUserId = createAsyncThunk(
    'notification/getnotificationstodaybyuserid',
    async (params: any | undefined, thunkAPI) => {
      return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.getNotificationByUserId,
        payload: params,
        options: {
          skipLoader: true,
        },
      });
    }
  );

export const readNotification = createAsyncThunk(
  'notification/readanotification',
  async (data: any | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: notificationApi.readNotification,
      payload: data,
      options: {
        skipLoader: true
      }
    })
  }
)

export const deleteNotification = createAsyncThunk('notification/deletenotification', async (params: any | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: notificationApi.deleteNotification,
    payload: params,
    options: {
      skipLoader: false
    }
  })
})

const notification = createSlice({
  name: 'notification',
  initialState: {},
  reducers: {
    // handleChangePage: (state, action) => {
    //   const { activePage, page } = action.payload
    //   state.paginationData[activePage].page = page
    // },
    // handleChangePageSize: (state, action) => {
    //   const { activePage, pageSize } = action.payload
    //   state.paginationData[activePage].pageSize = pageSize
    // },
    // handleChangeTotalPage: (state, action) => {
    //   const { activePage, totalPage = 1 } = action.payload
    //   state.paginationData[activePage].totalPage = totalPage
    // }
  },
  extraReducers: () => { }
})

const { reducer } = notification
export default reducer
