const environmentConfig = {
  development: {
    endPoint: 'https://gateway.dev.meu-solutions.com/vis-staging/api/v1',
  },
  production: {
    endPoint: 'https://gateway.dev.meu-solutions.com/vis-staging/api/v1',
  },
  empty: {
    endPoint: 'https://gateway.dev.meu-solutions.com/vis-staging/api/v1',
  }
}
export default environmentConfig
